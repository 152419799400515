<template>
  <esmp-modal
    v-model="isShowed"
    title="Удаление подключения к БД"
  >
    <div class="remove-connections-modal__text">
      Подтвердить удаление подключения к БД
    </div>
    <template #footer>
      <esmp-button @click="$emit('approve-remove-connection')">
        Удалить
      </esmp-button>
      <esmp-button view="outline" @click="isShowed = false">
        Отменить
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>
export default {
  name: 'ModalRemoveConnection',
  model: {
    prop: 'showed',
    event: 'input',
  },
  props: {
    showed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isShowed: {
      get() {
        return this.showed;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
